<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.4238 4.81244C13.4238 4.22889 13.4238 3.93712 13.3285 3.70696C13.2014 3.40009 12.9576 3.15628 12.6507 3.02917C12.4205 2.93384 12.1288 2.93384 11.5452 2.93384H5.28323C4.69969 2.93384 4.40791 2.93384 4.17776 3.02917C3.87089 3.15628 3.62708 3.40009 3.49996 3.70696C3.40463 3.93712 3.40463 4.22889 3.40463 4.81244M10.919 12.953H5.90943M9.51008 2.93384V12.953M7.31838 2.93384V12.953" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconText'
}
</script>
